<template>
    <NuxtLayout name="default">
        <GameMenu name="Anime Defenders" image="/images/games/icons/anime-defenders.webp" :links="links" />
        <NuxtLayout name="ads">
            <slot />
        </NuxtLayout>
    </NuxtLayout>
</template>

<script setup lang="ts">
const links = [
    { title: 'Items', to: '/anime-defenders/items' },
    { title: 'Units', to: '/anime-defenders/units' },
    { title: 'Trade Calculator', to: '/anime-defenders/calculate' },
    { title: 'Trade Ads', to: '/anime-defenders/ads' },
    { title: 'Value Changes', to: '/anime-defenders/value-changes' }
]

useHead({
    titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - Anime Defenders | RoValue` : 'Anime Defenders - RoValue'
    }
})
</script>
